import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslocoModule, provideTranslocoScope } from '@jsverse/transloco';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '@cca/ui';

@Component({
  selector: 'cca-sequence-confirm-dialog',
  standalone: true,
  imports: [TranslocoModule, MatDialogModule, MatButtonModule, IconComponent],

  templateUrl: './reset-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('sequence')],
})
export class SequenceResetDialogComponent {}
