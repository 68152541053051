import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CCASequenceStep } from '../../sequence';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { SequenceDevToolComponent } from '../dev-tool/dev-tool.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { IconComponent } from '@cca/ui';
import { StepViewModel } from '@cca-infra/sequence-management/v1';

@Component({
  selector: 'cca-sequence-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [provideTranslocoScope('sequence')],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslocoModule,
    MatButtonModule,
    MatMenuModule,
    IconComponent,
    SequenceDevToolComponent,
  ],
})
export class SequenceHeaderComponent {
  @Input() currentStep: CCASequenceStep | null = null;
  @Input() repeatableSteps: StepViewModel[] | null = null;
  @Input() canGoBack = false;
  @Input() isOnSummaryStep = false;
  @Input() showContinueButton = true;
  @Input() showBackButton = true;
  @Input({ required: true }) title: string | null = null;
  @Input({ required: true }) description: string | null = null;
  @Input({ required: true }) continueDisabled = false;
  @Input({ required: true }) repeatStepButtonText!: string;
  @Input() finishButtonLabel = '';
  @Input() showDevTools = false;
  @Input() hideHeaderDescription = false;

  @Output() backToPreviousStep = new EventEmitter<void>();
  @Output() continueToNextStep = new EventEmitter<void>();
  @Output() repeatStep = new EventEmitter<void>();

  get hasRepeatableSteps(): boolean {
    return !!this.repeatableSteps?.length;
  }

  protected continueOrFinish() {
    if (this.hasRepeatableSteps) this.repeatStep.emit();
    else this.continueToNextStep.emit();
  }
}
