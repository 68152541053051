<button type="button" class="button" mat-mini-fab (click)="openSheet()">
  <cca-icon icon="coachmark"></cca-icon>
</button>

<ng-template #sheetContents>
  <mat-nav-list>
    @if (showNavigation()) {
      <h2>Navigation:</h2>
      <div mat-list-item>
        <mat-form-field class="w-full">
          <mat-label>Current Step:</mat-label>
          <mat-select
            (selectionChange)="stepSelectionChanged($event)"
            [value]="store.currentActiveStep()?.stepKey"
          >
            @for (stepName of devStore.stepNames(); track stepName) {
              <mat-option [value]="stepName">{{ stepName }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }

    <h2 class="mb-2">Copy state</h2>
    <cca-auto-complete-field
      (changeEvent)="copyUserState($event)"
      [items]="filteredUsers$ | ngrxPush"
      [typeahead]="typeahead$"
      [closeOnSelect]="true"
      [minTermLength]="3"
      [typeToSearchText]="'Type to search usernames'"
      [bindLabel]="'userName'"
      [bindValue]="'userId'"
      placeholder="Copy user state"
      class="mb-4 block"
    >
    </cca-auto-complete-field>

    <h2>Logging:</h2>
    <p>click links below to log to console</p>
    <a mat-list-item (click)="logState()">Log state</a>
    <a mat-list-item (click)="logStep()">Log Step</a>
    <a mat-list-item (click)="logCurrentData()">Log currentData</a>
    <a mat-list-item (click)="logExpectedStepKeys()">Log Expected Step Keys</a>

    <h2>Data:</h2>
    <table class="w-full">
      <tr>
        <td>sequenceName</td>
        <td>{{ sequenceName() | json }}</td>
      </tr>
      <tr>
        <td>Error</td>
        <td>{{ store.error() | json }}</td>
      </tr>
      <tr>
        <td>loading</td>
        <td>{{ store.loading() | json }}</td>
      </tr>
      <tr>
        <td>previouslyCompleted</td>
        <td>{{ store['currentStepIsPreviouslyCompleted']() | json }}</td>
      </tr>
      <tr>
        <td>canContinue</td>
        <td>{{ store['canContinue']() | json }}</td>
      </tr>
      <tr>
        <td>sequenceId</td>
        <td>{{ store.sequenceId() | json }}</td>
      </tr>
      <tr>
        <td>sequenceStateId</td>
        <td>{{ store.sequenceStateId() | json }}</td>
      </tr>
    </table>
  </mat-nav-list>
</ng-template>
